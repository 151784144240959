import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media, convertMDContent} from '../../../utils'
import BackgroundTitle from '../../atoms/BackgroundTitle'
import Typography from '../../atoms/Typography'


const StyledTitle = styled(Typography)`
  margin: 0 6.125rem 0;
  font-size: ${({largeTitle}) => largeTitle ? '2.5rem' : '1.875rem'};
  font-weight: bold;

  p {
    line-height: 1.5;
  }

  ${media.down('mobile')(css`
    display: ${({mobileTitleVisible}) => mobileTitleVisible ? 'block' : 'none'};
    margin: 0 1.5rem;
  `)}
`

const SideTitleSection = ({title, largeTitle, mobileTitleVisible, fadedSideText, mobileFadedSideText, children,
  ...props}) => {

  return (
    <BackgroundTitle
        fadedSideText={fadedSideText}
        mobileFadedSideText={mobileFadedSideText}
        vertical
        {...props}
    >
      <StyledTitle
          type="div"
          largeTitle={largeTitle}
          mobileTitleVisible={mobileTitleVisible}
          dangerouslySetInnerHTML={{__html: convertMDContent(title)}}
      />
      {children}
    </BackgroundTitle>
  )
}

SideTitleSection.defaultProps = {
  mobileTitleVisible: false,
}

SideTitleSection.propTypes = {
  title: PropTypes.string.isRequired,
  largeTitle: PropTypes.bool,
  mobileFadedSideText: PropTypes.bool,
  mobileTitleVisible: PropTypes.bool,
  fadedSideText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default SideTitleSection
