import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {If} from 'babel-plugin-jsx-control-statements'
import {MainImage, getImage} from 'gatsby-plugin-image'
import useSlider from '../../../hooks/useSlider'
import {media} from '../../../utils'
import PrimaryButton from '../../atoms/PrimaryButton'
import ProgressIndicator from '../../atoms/ProgressIndicator'
import Transition from '../../atoms/Transition'


const StyledSliderWrapper = styled.div`
  position: relative;

  ${media.down('mobile')(css`
    padding-bottom: 1rem;
  `)}
`

const StyledImage = styled(MainImage)`
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100vh;
  object-fit: cover;

  ${media.down('mobile')(css`
    height: 85vh;
  `)}
`

const StyledNavbarContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`

const StyledIndicatorContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 6.5rem;
  left: 6rem;
  justify-content: flex-start;
  z-index: 2;

  ${media.down('mobile')(css`
    bottom: 3.5rem;
    left: 2rem;
  `)}
`

const StyledButtonContainer = styled.div`
  display: flex;
  position: absolute;
  right: 6rem;
  bottom: 6rem;
  justify-content: flex-end;
  width: 100%;

  ${media.down('mobile')(css`
    display: none;
  `)}
`

const HomePageHero = ({featuredImages, sliderSettings, slideButtonTitle, children}) => {
  const {activeSlide, handleSlide} = useSlider(featuredImages.length)

  return (
    <StyledSliderWrapper>
      <Transition transitionKey={activeSlide}>
        <StyledImage
            {...getImage(featuredImages[activeSlide].featuredImage).images}
            importance="high"
            alt={featuredImages[activeSlide].alt}
        />
      </Transition>
      <Transition transitionKey={activeSlide}>
        <StyledNavbarContainer>
          {children}
        </StyledNavbarContainer>
      </Transition>
      <If condition={featuredImages.length > 1}>
        <StyledIndicatorContainer>
          <ProgressIndicator
              value={activeSlide}
              maxLength={featuredImages.length}
              onClick={handleSlide}
              withArrows
              srOnly={sliderSettings}
          />
        </StyledIndicatorContainer>
      </If>
      <If condition={!isEmpty(slideButtonTitle)}>
        <Transition transitionKey={activeSlide}>
          <StyledButtonContainer>
            <PrimaryButton
                to="#sectionServices"
                arrowOrientation="down"
                transparent
            >
              {slideButtonTitle}
            </PrimaryButton>
          </StyledButtonContainer>
        </Transition>
      </If>
    </StyledSliderWrapper>
  )
}

HomePageHero.propTypes = {
  featuredImages: PropTypes.array.isRequired,
  sliderSettings: PropTypes.object.isRequired,
  slideButtonTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default HomePageHero
