import {useState} from 'react'


const useSlider = (count, defaultIndex = 0) => {
  const [activeSlide, setSlide] = useState(defaultIndex)

  const handleSlide = (value) => {
    if (value > count - 1) {
      return setSlide(0)
    }

    if (value < 0) {
      return setSlide(count - 1)
    }

    setSlide(value)
  }

  return {activeSlide, handleSlide}
}

export default useSlider
