import PropTypes from 'prop-types'
import {If} from 'babel-plugin-jsx-control-statements'
import useSlider from '../../../hooks/useSlider'
import VisualServicesRelation from '../../molecules/ServicesRelation'
import ProgressIndicator from '../../atoms/ProgressIndicator'


const ServicesRelation = ({services, sliderSettings, allServicesText, allServicesLink}) => {
  const {activeSlide, handleSlide} = useSlider(services.length)

  return (
    <VisualServicesRelation
        serviceIndex={activeSlide}
        services={services}
        allServicesText={allServicesText}
        allServicesLink={allServicesLink}
    >
      <If condition={services.length > 1}>
        <ProgressIndicator
            value={activeSlide}
            maxLength={services.length}
            onClick={handleSlide}
            withArrows
            srOnly={sliderSettings}
        />
      </If>
    </VisualServicesRelation>
  )
}

ServicesRelation.propTypes = {
  services: PropTypes.array,
  sliderSettings: PropTypes.object,
  allServicesText: PropTypes.string,
  allServicesLink: PropTypes.string,
}

export default ServicesRelation
