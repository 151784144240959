import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {ChevronIcon} from '../../../assets/icons'
import Typography from '../Typography'


const StyledButton = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;

  ${({left}) => left && css`
    transform: rotate(180deg);
  `}

  ${({disabled}) => disabled && css`
    opacity: 0.3;
    cursor: default;
    filter: grayscale(1);
  `}
`

const ChevronButton = ({disabled, onClick, children, ...props}) => {
  return (
    <StyledButton type="button" disabled={disabled} onClick={onClick} {...props}>
      <ChevronIcon />
      <Typography type="span" srOnly>{children}</Typography>
    </StyledButton>
  )
}

ChevronButton.defaultProps = {
  disabled: false,
}

ChevronButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default ChevronButton
