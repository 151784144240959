import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {media} from '../../../utils'
import Typography from '../../atoms/Typography'
import PrimaryButton from '../../atoms/PrimaryButton'
import Transition from '../../atoms/Transition'
import Image from '../../atoms/Image'


const StyledServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 7.5rem 0 7.5rem 12.5rem;

  ${media.down('mobile')(css`
    flex-direction: column;
    align-items: center;
    margin: 3rem 1.5rem;
  `)}
`

const StyledImageWrapper = styled.div`
  position: relative;
  width: 50%;
  height: 32rem;
  overflow: hidden;

  ${media.down('mobile')(css`
    display: flex;
    justify-content: center;
    width: 100%;
  `)}
`

const StyledImage = styled(Image)`
  position: absolute;
  max-width: 100%;
  height: 100%;
  object-fit: contain;

  ${media.down('mobile')(css`
    position: unset;
    width: 100%;
  `)}
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  padding: 0 10rem;
  width: 100%;
  text-align: start;

  ${media.down('mobile')(css`
    margin-top: 0;
    padding: 4rem 0 0;
    width: 100%;
  `)}
`

const StyledSliderWrapper = styled.div`
  padding-bottom: 4rem;

  ${media.down('mobile')(css`
    padding-bottom: 1.5rem;
  `)}
`

const StyledTitle = styled(Typography)`
  padding-bottom: 3rem;

  ${media.down('mobile')(css`
    padding-bottom: 1.5rem;
  `)}
`

const StyledDescription = styled(Typography)`
  padding-bottom: 3rem;
`

const ServicesRelation = ({serviceIndex, services, allServicesText, allServicesLink, children, ...props}) => (
  <StyledServicesContainer {...props}>
    <StyledImageWrapper>
      <Transition transitionKey={serviceIndex}>
        <StyledImage
            image={services[serviceIndex].hero.featuredImage.image}
            alt={services[serviceIndex].hero.featuredImage.alt}
        />
      </Transition>
    </StyledImageWrapper>
    <StyledContentWrapper>
      <If condition={children}>
        <StyledSliderWrapper>
          {children}
        </StyledSliderWrapper>
      </If>
      <Transition transitionKey={serviceIndex}>
        <StyledTitle color="primary" variant="h3" type="h2">{services[serviceIndex].relation.title}</StyledTitle>
      </Transition>
      <Transition transitionKey={serviceIndex}>
        <StyledDescription>{services[serviceIndex].relation.description}</StyledDescription>
      </Transition>
      <PrimaryButton
          to={allServicesLink}
      >
        {allServicesText}
      </PrimaryButton>
    </StyledContentWrapper>
  </StyledServicesContainer>
)

ServicesRelation.propTypes = {
  serviceIndex: PropTypes.number.isRequired,
  services: PropTypes.array.isRequired,
  allServicesText: PropTypes.string,
  allServicesLink: PropTypes.string,
  children: PropTypes.node,
}

export default ServicesRelation
