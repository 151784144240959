import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {padStart} from 'lodash-es'
import ChevronButton from '../ChevronButton'


const StyledProgressIndicator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  > span {
    &:first-child {
      padding: 0 1rem 0 0;
    }

    &:last-child {
      padding: 0 0 0 1rem;
    }
  }
`

const StyledNumber = styled.span`
  padding: ${({left}) => left ? '0 1rem 0 0.5rem' : '0 0.5rem 0 1rem'};
  width: 2.5rem;
  font-size: 0.875rem;
  font-weight: ${({theme}) => theme.components.typography.weight.semiBold};

  ${({left}) => left && css`
    color: ${({theme}) => theme.colors.primary};
  `}
`

const StyledProgress = styled.progress`
  appearance: none;
  border: 0;
  width: 3.75rem;
  height: 0.15rem;

  ::-webkit-progress-bar {
    border-radius: 2rem;
    background: ${({theme}) => `${theme.colors.secondary}20`};
  }

  ::-moz-progress-bar {
    border-radius: 2rem;
    background-color: ${({theme}) => theme.colors.primary};
  }

  ::-webkit-progress-value {
    border-radius: 2rem;
    background-color: ${({theme}) => theme.colors.primary};
  }
`

const ProgressIndicator = ({value, maxLength, onClick, withArrows, srOnly, ...props}) => (
  <StyledProgressIndicator withArrows={withArrows} {...props}>
    <If condition={withArrows}>
      <ChevronButton left onClick={() => onClick(value - 1)}>
        {srOnly.chevronLeftSrOnly}
      </ChevronButton>
    </If>
    <StyledNumber left>{padStart(value + 1, 2, '0')}</StyledNumber>
    <StyledProgress value={value + 1} max={maxLength} />
    <StyledNumber>{padStart(maxLength, 2, '0')}</StyledNumber>
    <If condition={withArrows}>
      <ChevronButton onClick={() => onClick(value + 1)}>
        {srOnly.chevronRightSrOnly}
      </ChevronButton>
    </If>
  </StyledProgressIndicator>
)

ProgressIndicator.defaultProps = {
  withArrows: false,
}

ProgressIndicator.propTypes = {
  value: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  withArrows: PropTypes.bool,
  srOnly: PropTypes.object,
}

export default ProgressIndicator
